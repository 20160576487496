import React, { useState, useContext, useCallback, useEffect } from 'react';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import { AlertMessagesContext } from 'react-alert-messages';
import { useParams, Link } from 'react-router-dom';
import RegistrationKeyServices from '../../../services/RegistrationKeyServices';
import { Grid, TextField, MenuItem, Button } from '@material-ui/core';
import { SUBSCRIPTIONS, ROUTES, SUBSCRIPTION_KEYS, YEARLY_SUB_PERIOD, MONTHLY_SUB_PERIOD } from '../../../const';
import { Typography } from '@mui/material';
import DealerService from '../../../services/DealerServices';

function NewRegistrationKey() {
  const history = useHistory();
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { keyId } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    count: 1,
    remarks: '',
    dealer_id: '',
    group_code: null,
    plan: {
      subscription: { key: 'premium', validity: MONTHLY_SUB_PERIOD },
      add_ons: {},
    },
  });
  const [dealers, setDealers] = useState([]);

  const handleChangeValidity = (feature, value) => {
    setData((prevData) => {
      const currentValidity = prevData?.plan?.add_ons?.[feature]?.validity || 0;
      const additionalValidity = value?.validity || 0;
      const { validity, ...restOfValue } = value || {};

      return {
        ...prevData,
        plan: {
          ...prevData.plan,
          add_ons: {
            ...prevData.plan.add_ons,
            [feature]: {
              ...prevData.plan.add_ons[feature],
              ...restOfValue,
              validity: additionalValidity === 0 ? additionalValidity : currentValidity + additionalValidity,
            },
          },
        },
      };
    });
  };

  const handleChangeSubValidity = (validity) => {
    setData((prevData) => ({
      ...prevData,
      plan: {
        ...prevData.plan,
        subscription: {
          ...prevData.plan.subscription,
          validity: validity ? prevData.plan.subscription.validity + validity : validity,
        },
      },
    }));
  };

  const getKey = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await RegistrationKeyServices.getKey(keyId);
      resp.data.plan.add_ons = resp?.data?.plan?.add_ons.reduce((acc, item) => {
        acc[item.key] = item;
        return acc;
      }, {});
      resp.data.dealer_id = resp.data?.dealer?.id;
      setData(resp.data);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoading(false);
  }, [keyId, postAlertMessage]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const adonData = Object.keys(data.plan.add_ons).map((key) => ({
        key,
        ...data.plan.add_ons?.[key],
      }));

      const keyData = {
        ...data,
        plan: { ...data.plan, add_ons: adonData },
      };

      if (data.group_code && data.group_code.length > 25) {
        throw Error('Group code must not exceed 25 characters.');
      }

      if (keyId) {
        const resp = await RegistrationKeyServices.updateKey(keyId, keyData);
        setData(resp);
        postAlertMessage({
          text: 'Key updated successfully',
          type: 'success',
        });
      } else {
        const resp = await RegistrationKeyServices.createKey(keyData);
        setData(resp);
        postAlertMessage({
          text: 'Keys created successfully',
          type: 'success',
        });
        history.push(`${ROUTES.REGISTRATION_KEY}?group_code=${resp.data[0]}`);
      }
      console.log(keyData);
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoading(false);
  };

  const getDealers = async () => {
    setLoading(true);
    try {
      const res = await DealerService.getDealers();
      setDealers(res?.data);
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoading(false);
  };

  useEffect(() => {
    getDealers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (keyId) {
      getKey();
    }
  }, [keyId, getKey]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loading} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Registration Key<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Registration Key Details</h2>
          </div>
        </div>
        {!data.id && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Number of keys</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                value={data?.count}
                onChange={(e) => {
                  setData({ ...data, count: Number(e.target.value) });
                }}
              />
            </Grid>
          </Grid>
        )}
        {data.id && (
          <>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Key ID</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField variant="outlined" size="small" fullWidth type="text" disabled value={data?.id} />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Secret</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  value={data?.secret}
                  onChange={(e) => {
                    setData({ ...data, secret: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Subscription</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              name="fromDate"
              size="small"
              defaultValue={data?.plan?.subscription?.key || ''}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({
                  ...data,
                  plan: {
                    ...data?.plan,
                    subscription: {
                      ...data?.plan?.subscription,
                      key: SUBSCRIPTION_KEYS[Number(event.target.value)],
                    },
                  },
                });
              }}
            >
              {Object.keys(SUBSCRIPTIONS).map((subType) => (
                <MenuItem key={subType} value={subType}>
                  {SUBSCRIPTIONS[subType]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Dealer</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              size="small"
              defaultValue={data?.dealer_id || ''}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({ ...data, dealer_id: Number(event.target.value) });
              }}
            >
              {dealers.map((dealer) => (
                <MenuItem key={dealer?.id} value={dealer?.id}>
                  {dealer?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Subscription validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.plan?.subscription?.validity} days
                </Typography>
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleChangeSubValidity(0)}
                  color="primary"
                  disabled={!data?.plan?.subscription?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeSubValidity(MONTHLY_SUB_PERIOD)}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeSubValidity(YEARLY_SUB_PERIOD)}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Kot validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.plan?.add_ons?.kot?.validity || 0} days
                </Typography>
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('kot', { validity: null })}
                  color="primary"
                  disabled={!data?.plan?.add_ons?.kot?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('kot', { validity: MONTHLY_SUB_PERIOD })}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('kot', { validity: YEARLY_SUB_PERIOD })}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Kot lite validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.plan?.add_ons?.kot_lite?.validity || 0} days
                </Typography>
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('kot_lite', { validity: null })}
                  color="primary"
                  disabled={!data?.plan?.add_ons?.kot_lite?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('kot_lite', { validity: MONTHLY_SUB_PERIOD })}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('kot_lite', { validity: YEARLY_SUB_PERIOD })}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Image support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.plan?.add_ons?.image?.validity || 0} days
                </Typography>
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('image', { validity: null })}
                  color="primary"
                  disabled={!data?.plan?.add_ons?.image?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('image', { validity: MONTHLY_SUB_PERIOD })}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('image', { validity: YEARLY_SUB_PERIOD })}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Quickbook support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.plan?.add_ons?.external_api?.validity || 0} days
                </Typography>
              </Grid>

              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('external_api', { validity: null })}
                  color="primary"
                  disabled={!data?.plan?.add_ons?.external_api?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('external_api', { validity: MONTHLY_SUB_PERIOD })}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('external_api', { validity: YEARLY_SUB_PERIOD })}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Customer support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.plan?.add_ons?.customer?.validity || 0} days
                </Typography>
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('customer', { validity: null })}
                  color="primary"
                  disabled={!data?.plan?.add_ons?.customer?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('customer', { validity: MONTHLY_SUB_PERIOD })}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('customer', { validity: YEARLY_SUB_PERIOD })}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Bluetooth support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.plan?.add_ons?.bluetooth_scale?.validity || 0} days
                </Typography>
              </Grid>

              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('bluetooth_scale', { validity: null })}
                  color="primary"
                  disabled={!data?.plan?.add_ons?.bluetooth_scale?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('bluetooth_scale', { validity: MONTHLY_SUB_PERIOD })}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('bluetooth_scale', { validity: YEARLY_SUB_PERIOD })}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Smart scale validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.plan?.add_ons?.smart_scale?.validity || 0} days
                </Typography>
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('smart_scale', { validity: null })}
                  color="primary"
                  disabled={!data?.plan?.add_ons?.smart_scale?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('smart_scale', { validity: MONTHLY_SUB_PERIOD })}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('smart_scale', { validity: YEARLY_SUB_PERIOD })}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Expense validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={3} md={5} lg={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" pt={1.5} gutterBottom>
                  {data?.plan?.add_ons?.expense?.validity || 0} days
                </Typography>
              </Grid>
              <Grid item xs={9} md={7} lg={6} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('expense', { validity: null })}
                  color="primary"
                  disabled={!data?.plan?.add_ons?.expense?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('expense', { validity: MONTHLY_SUB_PERIOD })}
                  color="secondary"
                  style={{ marginRight: '5px' }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleChangeValidity('expense', { validity: YEARLY_SUB_PERIOD })}
                  color="secondary"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>ECS terminal count</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.plan?.add_ons?.ecs_terminal?.count || null}
              onChange={(e) => {
                handleChangeValidity('ecs_terminal', {
                  count: Number(e.target.value),
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>POS terminal count</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.plan?.add_ons?.pos_terminal?.count || null}
              onChange={(e) => {
                handleChangeValidity('pos_terminal', {
                  count: Number(e.target.value),
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Remarks</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.remarks}
              onChange={(e) => {
                setData({ ...data, remarks: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid item xs={12} md={3} spacing={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Group Code</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.group_code}
              onChange={(e) => {
                setData({ ...data, group_code: e.target.value });
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container className={styles.submitSection} justifyContent="flex-end">
          <Link to={ROUTES.REGISTRATION_KEY} className={styles.link}>
            <Button variant="contained" color="secondary" className={styles.closeBtn}>
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#00a65a', marginLeft: '10px' }}
            onClick={handleSubmit}
          >
            {data.id ? 'Save Changes' : 'Create key'}
          </Button>
        </Grid>
      </div>
    </div>
  );
}

export default withConsoleBase(NewRegistrationKey);
